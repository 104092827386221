import {region} from '../api/config'
import {CurrencyCode} from '../api/getProductPrimitives'
import {isFramed} from '../utils/helpers'
import {nonErrorlog} from '../utils/logging'

const w = window as any

const shopIdName = '__sdx_project_id'
const initedShopName = '__sdx_jstag_initialized_shop'

export const fetchJsTag = () => {
  const script = document.createElement('script')

  script.onload = () => initJsTag()
  script.src = 'https://tag.ur2inc.com/sdx-tag.js?v=11'

  if (region === 'dev-use2') script.src = 'https://tag.ur2inc.com/sdx-dev.js'
  if (region === 'stage-use2') script.src = 'https://tag.ur2inc.com/sdx-stage.js'

  w['__sdx_ldx'] = true // flag this as not a brand site

  document.body.appendChild(script)
}

export const fetchHeadScript = async (shopName: string) => {
  let scriptBase = 'd1ngzejyp2v34w.cloudfront.net'

  if (region === 'dev-use2') scriptBase = 'd33sc73c7119ej.cloudfront.net'
  if (region === 'stage-use2') scriptBase = 'db8entz45aaaj.cloudfront.net'
  const scriptUrl = `https://${scriptBase}/head_scripts/${shopName}/head.html`

  try {
    const response = await fetch(scriptUrl)
    if (response.ok) {
      const script = await response.text()
      if (script) {
        const divFragment = document.createRange().createContextualFragment(script)
        document.head.appendChild(divFragment)
      }
      else {
        console.error('No script URL:', scriptUrl)
      }
    }
  }
  catch (error) {
    console.error('Error fetching the script:', error)
  }
}

const setShop = (shop?: string) => {
  // NOTE: setShop is called from both script.onload and initJsTag, see if shop was stored
  if (!shop) shop = w[shopIdName]

  if (!shop) {
    console.warn('jstag: no shop found')
    return
  }

  if (w[initedShopName] === shop) {
    console.warn('jstag: already initialized', {shop})
    return
  }

  // store the shop for script.onload if it loads later and calls setShop with no shop param
  w[shopIdName] = shop
  w['__sdx_config_url'] = `https://config.ur2inc.com/shop/${shop}.conf`

  const sdxCapture = w['sdxCapture']
  if (!sdxCapture) {
    console.warn('jstag: not found')
    return
  }

  if (typeof sdxCapture.initAnalytics !== 'function') {
    console.warn('jstag: initAnalytics not a function')
    return
  }
  sdxCapture.initAnalytics(shop)
  w[initedShopName] = shop

  nonErrorlog(`jstag: initialized: userId ${sdxCapture.userId}; sessionId ${sdxCapture.sessionId}; experienceId ${sdxCapture.experienceId || null}`)
}

export const initJsTag = (shopName?: string) => {
  if (isFramed) {
    console.warn('jstag: not initializing in iframe')
    return
  }
  if (window.location.hostname === 'localhost') {
    console.warn('jstag: not initializing on localhost')
    return
  }

  setShop(shopName)
}


export type SdxCaptureCartFormat = {
  currency: CurrencyCode
  contents: {
    product: {
      id: string
      externalId: string
    }
    variant: {
      id: string
      externalId: string
    }
    quantity: number
    price: number
  }[]
}

export type SdxCapture = {
  addToCartEvent: (props: SdxCaptureCartFormat) => void
  checkoutInitiatedEvent: (props: SdxCaptureCartFormat) => void
}

export const getSdxCapture = (): SdxCapture => {
  let sdxCapture = w['sdxCapture']
  if (!sdxCapture) {
    console.warn('sdxCapture not found')
    sdxCapture = {} as any
  }

  if (typeof sdxCapture.addToCartEvent !== 'function')
    sdxCapture.addToCartEvent = () => console.warn('sdxCapture.addToCartEvent not found')

  if (typeof sdxCapture.checkoutInitiatedEvent !== 'function')
    sdxCapture.checkoutInitiatedEvent = () => console.warn('sdxCapture.checkoutInitiatedEvent not found')

  return sdxCapture as SdxCapture
}
