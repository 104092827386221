import {CartItem} from '../config'
import {getCheckoutAttributes} from '../services/checkout'
import {ecommerceServiceBaseUrl} from './config'
import {Merchant} from './getMerchant'

type GetPermalinkBody = {
  variantData: LineItem[]
}
type LineItem = {
  quantity: number
  externalId: string
}
type GetPermalinkResponse = {
  cartPermalink: string
}

export const getCheckoutLinkFromCartItems = async (shopDomain: string, merchant: Merchant, cartItems: CartItem[]): Promise<string> => {
  if (cartItems.length === 0) {
    console.error('getCheckoutLinkFromCartItems: cart is empty')
    return ''
  }
  const body = apiBodyFromCartItems(cartItems)
  const attributes = getCheckoutAttributes(merchant)

  const config : RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Shop-Domain': shopDomain,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }
  const url = `${ecommerceServiceBaseUrl}/v1/permalink`

  const response: GetPermalinkResponse = await fetch(url, config)
    .then(async (response) => {
      if (!response.ok) {
        const errBody = await response.json()
        throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
      }

      return response.json()
    } )
    .catch(err => {
      throw new Error(`fetch request failed: ${err}`)
    })

  return response.cartPermalink + attributes
}

// apiBodyFromCartItems parses the cart item objects into the format the api expects.
const apiBodyFromCartItems = (cartItems: CartItem[]): GetPermalinkBody => {
  return {
    variantData: cartItems.map(x => {
      return {
        externalId: x.variant.externalId,
        quantity: x.quantity,
      }
    }),
  }
}