import {logging} from '../utils/logging'
import {ContainerConfig} from './components'
import {componentServiceBaseUrl} from './config'

export const getComponentConfig = async (componentId: string, shopDomain: string, componentType?: string) => {
  try {
    const baseUrl = `${componentServiceBaseUrl}/v1/components`
    let url = `${baseUrl}/${componentId}`
    if (componentType) url = `${url}?componentType=${componentType}`

    const componentConfig: ContainerConfig = await fetch(url, {mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Shop-Domain':shopDomain,

      'pragma': 'no-cache',
      'cache-control': 'no-store',
    }})
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(`fetch request failed: ${err}`)
      })

    return componentConfig
  }
  catch (error) {
    logging(error, {tags: {section: 'asyncGetComponentConfig'}})
  }
}
