import {useEffect, useContext, useState, lazy} from 'react'
import {ThemeProvider, ThemeOptions} from '@mui/material/styles'
import {createTheme} from '@mui/material/styles'
import {ConfigContext} from './config/context'
import {getDefaultTheme} from './utils/theme'
import {merge} from 'ts-deepmerge'
import {fontWithDefaults} from './defaultFonts'
import {logging} from './utils/logging'
import {StyleFont} from './config'
import {DeepRequiredPrimitiveSettings, defaultPrimitiveSettings, getPrimitiveSettings, PrimitiveSettings} from './api/primitiveSettings'
import {} from './utils/helpers'
import {getLastFontPath} from './utils/setFonts'

const Home = lazy(() => import('./components/Home'))
const CssBaseline = lazy(() => import('@mui/material/CssBaseline'))

export default function App() {
  const {clientStyles, pageConfig, shopDomain, setPrimitiveSettings} = useContext(ConfigContext)

  const [theme, setTheme] = useState(createTheme(getDefaultTheme()))

  useEffect(() => {
    if (!clientStyles) return

    // set the fonts and create a theme
    const fontFace: {fontFamily: string, src: string, fontDisplay: string}[] = []

    if (clientStyles.fonts?.main) clientStyles.fonts.main.name = `SdxFontMain-${getLastFontPath(clientStyles.fonts.main.url)}`
    if (clientStyles.fonts?.header) clientStyles.fonts.header.name = `SdxFontHeader-${getLastFontPath(clientStyles.fonts.header.url)}`

    const fonts: StyleFont[] = [
      clientStyles.fonts.main,
      clientStyles.fonts.header,
    ]

    const fontMapper: {[key: string]: string} = {
      'woff': 'woff',
      'woff2': 'woff2',
      'ttf': 'truetype',
      'otf': 'opentype',
    }

    fonts.forEach(font => {
      if (!font.name) return

      const paramlessUrl = font.url.split('?')[0]
      const extensionIndex = paramlessUrl.lastIndexOf('.')
      const fontFormatType = paramlessUrl.substring(extensionIndex + 1) || ''
      const fontFormatValue = fontMapper[fontFormatType.toLowerCase()]

      if (!fontFormatValue) {
        console.warn(`Font format ${fontFormatType} not found in fontMapper.`)
      }
      fontFace.push({
        fontFamily: font.name,
        src: `url(${font.url})` + (fontFormatValue ? ` format(${fontFormatValue})`: ''),
        fontDisplay: 'swap',
      })
    })

    const newPartialTheme: Partial<ThemeOptions> = {
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            // NOTE: https://github.com/mui/material-ui/issues/24966#issuecomment-973929411
            fallbacks: [
              ...fontFace.map(f => ({'@font-face': f})),
            ],
          },
        },
      },
      typography: {
        fontFamily: fontWithDefaults(clientStyles.fonts.main.name),
      },
      palette: {
        text: {
          primary: '#344054',
        },
        primary: {
          main: clientStyles.colors.primary,
        },
        secondary: {
          main: clientStyles.colors.secondary,
        },
      },
    }
    const newCustomTheme = {
      palette: {
        custom: {
          header_font_name: clientStyles.fonts.header?.name,
        } as any,
      },
    }
    const mergedTheme = merge.withOptions({mergeArrays: false},
      getDefaultTheme(),
      newPartialTheme,
      newCustomTheme,
    )
    setTheme(createTheme(mergedTheme))
  }, [clientStyles])

  useEffect(() => {
    if (!pageConfig) return
    document.title = pageConfig.headline
  }, [pageConfig])

  useEffect(() => {
    const asyncGetPrimitiveSettings = async () => {
      try {
        if ( shopDomain === '') return // don't want to call this with the initial empty state for shop.
        const response: PrimitiveSettings | undefined = await getPrimitiveSettings(shopDomain)
        if (response) {
          const blendedSettings: DeepRequiredPrimitiveSettings = merge(defaultPrimitiveSettings, response) as DeepRequiredPrimitiveSettings
          setPrimitiveSettings(blendedSettings)
        }
      }
      catch (error) {
        logging(error, {tags: {section: 'home app error'}})
      }
    }
    asyncGetPrimitiveSettings()
  }, [shopDomain, setPrimitiveSettings])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Home />
    </ThemeProvider>
  )
}
