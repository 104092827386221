import {productServiceUrl} from './config'
import {VariantDetail, ImageType, OptionValue} from '../config'
import {getCurrencySymbolFromCode, normalizeImage} from './getProductsByIds'
import {logging} from '../utils/logging'
import {CurrencyCode, CurrencySymbol} from './getProductPrimitives'

export const normalizeVariantDetail = (variantDetail: VariantDetail): NormalizedVariantDetail => {
  return {
    productId: variantDetail.productId,
    productExternalId: variantDetail.productExternalId,
    productTitle: variantDetail.productTitle,
    featuredImage: normalizeImage(variantDetail.featuredImage),

    id: variantDetail.id,
    externalId: variantDetail.externalId,
    title: variantDetail.title,
    images: variantDetail.images.map(normalizeImage),
    sku: variantDetail.sku,

    available: variantDetail.available,
    currency: getCurrencySymbolFromCode(variantDetail.currencyCode as CurrencyCode),
    price: variantDetail.price,
    comparePrice: variantDetail.comparePrice,
    allowOosOrder: variantDetail.allowOosOrder,
    quantity: variantDetail.quantity,

    optionValues: variantDetail.optionValues,
  }
}

export type GetVariantDetailsOptions = {
  variantIds?: string[]
  variantExternalIds?: string[]
  limit?: number
  offset?: number
}

export const getVariantDetails = async (shopDomain: string, options: GetVariantDetailsOptions) => {
  try {
    const {variantIds, variantExternalIds, limit, offset} = options

    // const variants = await
    const config : RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Shop-Domain': shopDomain,
      },
    }

    const query = {} as any
    if (variantIds) query['variantIds'] = variantIds.join(',')
    else if (variantExternalIds) query['variantExternalIds'] = variantExternalIds.join(',')
    if (limit) query['limit'] = limit
    if (offset) query['offset'] = offset

    const queryString = new URLSearchParams(query).toString()
    const url = `${productServiceUrl}/v1/variants/?${queryString}`

    const response: VariantDetail[] = await fetch(url, config)
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(`fetch request failed: ${err}`)
      })

    return response.map(normalizeVariantDetail)
  }
  catch (error) {
    logging(error, {tags: {section: 'getVariantDetails'}})
    return []
  }
}

export type NormalizedVariantDetail = {
  productId: string
  productExternalId: string
  productTitle: string
  featuredImage?: ImageType

  id: string
  externalId: string
  title: string
  images: ImageType[]
  sku: string

  available: boolean
  currency: CurrencySymbol
  price: number
  comparePrice: number | null
  quantity: number
  allowOosOrder: boolean

  optionValues: OptionValue[]
}