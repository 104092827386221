import {ecommerceServiceBaseUrl} from './config'
import {logging} from '../utils/logging'

export type Merchant = {
  shop: string
  status: 'ALLOWLISTED' | 'REGISTERED' | 'INSTALLED' | 'UNINSTALLED' | 'INACTIVE'
  platform: 'SHOPIFY' | 'FEED' | 'SALESFORE_COMMERCE_CLOUD'
  plan: 'NONE' | 'BASE' | 'ENTERPRISE'
}

export const defaultMerchant: Merchant = {
  shop: '',
  status: 'UNINSTALLED',
  platform: 'SHOPIFY',
  plan: 'NONE',
}

export const getMerchantApi = async(shopDomain: string) => {
  try {
    const config : RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Shop-Domain': shopDomain,
      },
    }
    const url = `${ecommerceServiceBaseUrl}/v1/merchant`

    const response: Merchant = await fetch(url, config)
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody}`)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(`fetch request failed: ${err}`)
      })

    return response
  }
  catch (e) {
    logging(e, {tags: {section: 'getMerchantApi'}})
    return null
  }
}